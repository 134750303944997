<template>
  <div class="row">
    <div class="col-12 p-3 bg-danger text-center text-white text-center">
      <h6>{{ $t('repayment_is_late') }}</h6>
      <h6 v-if="total <= 5" class="mt-4">
        {{ $t('repayment_is_late_description', { total: total, amount: amount }) }}
      </h6>
      <h6 v-else class="mt-4" v-html="$t('repayment_late_more_than_five', { total: total, amount: amount })"></h6>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PaymentLate',
  // eslint-disable-next-line vue/require-prop-types
  props: ['total', 'amount'],
}
</script>

<style></style>
