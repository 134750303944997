<template>
  <div class="container" fluid="lg">
    <user-header></user-header>

    <payment-late
      v-if="totalLateDate"
      :total="totalLateDate"
      :amount="paymentScheduleList[currentPeroid].pmt_amount"
    ></payment-late>

    <div class="row sticky-top bg-white">
      <div class="d-flex align-items-center justify-content-between w-100 my-3">
        <h5>{{ loanApplication ? loanApplication.contract_no_ref : '-' }}</h5>
        <h6
          class="text-primary text-right font-weight-bold cursor-pointer"
          @click="$bvModal.show('modal_barcode')"
          v-html="$t('view_barcode')"
        ></h6>
      </div>
      <nav-bar v-model="active" :nav_list="nav_list"></nav-bar>
    </div>

    <div v-if="active == 1" class="row">
      <div v-if="loanApplication" class="col-12 p-0 m-0">
        <div v-if="status == 'disbursed'">
          <div v-for="(paymentSchedule, index) in paymentScheduleList" :key="index" class="d-flex p-3">
            <div class="pr-3">
              <b-avatar
                v-if="index == currentPeroid"
                class="align-middle logo-avatar"
                :src="image.logo"
                size="2.75rem"
                variant="primary"
              ></b-avatar>
              <b-avatar
                v-else
                class="align-middle"
                size="2.75rem"
                :variant="index < currentPeroid ? 'success' : 'secondary'"
              >
                <i class="ri-check-line ri-lg" :class="index < currentPeroid ? 'text-white' : 'text-secondary'"></i>
              </b-avatar>
              <div v-show="index != paymentScheduleList.length - 1" class="mx-auto vertical-divider"></div>
            </div>
            <div class="w-100">
              <h6 class="m-0">{{ repaymentPeriod(paymentSchedule.period) }}</h6>
              <h6 class="mt-2">
                {{ $t('repayment_amount') }} : {{ paymentSchedule.pmt_amount | Currency }} {{ $t('bahts') }}
              </h6>
              <div class="small text-light mt-2">
                {{ $t('repayment_date') }}:
                {{ paymentSchedule.pmt_date | DateFormatter }}
              </div>

              <div v-if="index < currentPeroid">
                <b-button
                  size="sm"
                  class="w-100 mt-2"
                  pill
                  variant="primary"
                  :href="getUrl(paymentSchedule.invoice_url)"
                  target="_blank"
                >
                  {{ $t('view_invoice') }}
                </b-button>
                <b-button
                  size="sm"
                  class="w-100 mt-2"
                  pill
                  variant="primary"
                  :href="getUrl(paymentSchedule.receipt_url)"
                  target="_blank"
                  >{{ $t('view_receipt') }}</b-button
                >
              </div>

              <div v-if="index == currentPeroid">
                <b-button
                  v-if="paymentSchedule.invoice_response"
                  size="sm"
                  class="w-100 mt-2"
                  pill
                  variant="primary"
                  :href="getUrl(paymentSchedule.invoice_url)"
                  target="_blank"
                  >{{ $t('view_invoice') }}</b-button
                >
                <router-link :to="{ name: 'payment', params: { type: 'branch' } }">
                  <b-button size="sm" class="w-100 mt-2" pill variant="outline-primary">{{
                    $t('repay_at_branch')
                  }}</b-button>
                </router-link>
                <router-link :to="{ name: 'payment', params: { type: 'atm' } }">
                  <b-button size="sm" class="w-100 mt-2" pill variant="outline-primary">{{
                    $t('repay_at_atm')
                  }}</b-button>
                </router-link>
                <router-link :to="{ name: 'payment', params: { type: 'mobile-banking' } }">
                  <b-button size="sm" class="w-100 mt-2" pill variant="outline-primary">{{
                    $t('repay_mobile_banking')
                  }}</b-button>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="active == 2" class="row py-4 px-2">
      <div v-if="paymentScheduleList.length > 0" class="col-12">
        <div class="row">
          <div class="col-6">
            <h6>{{ $t('loan_size') }}</h6>
          </div>
          <div class="col-6 text-right">
            <h6>{{ loanInformation.principle_amount | Currency }} {{ $t('bahts') }}</h6>
          </div>
          <div class="col-6">
            <h6>{{ $t('loan_term') }}</h6>
          </div>
          <div class="col-6 text-right">
            <h6>{{ loanInformation.term_period }} {{ $t('months') }}</h6>
          </div>
          <div class="col-12 text-center mt-4">
            <b-button
              v-if="loanInformation.loan_agreement_signed_copy"
              size="sm"
              class="my-2"
              pill
              variant="primary"
              :href="loanInformation.loan_agreement_signed_copy"
              target="_blank"
            >
              {{ $t('view_loan_agreement') }}
            </b-button>
          </div>
        </div>
      </div>
      <div v-else class="col-12 mt-5">
        <div class="text-center">{{ $t('no_loan_information') }}</div>
      </div>
    </div>

    <loading :is-busy="isBusy"></loading>
    <b-modal id="modal_barcode" centered scrollable size="sm" hide-header hide-footer content-class="border-0 w-auto">
      <div class="text-center">
        <div class="p-4">
          <img :src="paymentReferencePhoto" class="w-100" />
          <div style="font-size: 11px" class="mt-4">{{ paymentReferenceNumber }}</div>
        </div>
        <!-- <barcode v-bind:value="paymentReferenceNumber" class="w-100"> </barcode> -->
        <input id="testing-code" type="hidden" :value="paymentReferenceNumber" />
        <div class="bg-white mx-auto">
          <b-button pill class="px-4 my-3" variant="primary" @click="copyToClipboard()">{{
            $t('copy_number')
          }}</b-button>
          <!-- <b-button pill variant="outline-primary" size="sm" class="ml-3" @click="downloadReferenceNumber()">
            <b-icon-download></b-icon-download>
          </b-button> -->
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import UserHeader from '@/components/UserHeader.vue'
import NavBar from '@/components/NavBar.vue'
import PaymentLate from '@/components/PaymentLate.vue'
import Loading from '@/components/Loading.vue'
import LoanService from '@/api/service/LoanService'
import moment from 'moment'
export default {
  components: { UserHeader, NavBar, PaymentLate, Loading },
  data() {
    return {
      active: 1,
      image: {
        logo: require('@/assets/images/logo.png'),
      },
      style: {
        active: 'border-bottom border-primary border-2 text-primary',
      },
      nav_list: [
        { key: 1, value: 'payment_schedule' },
        { key: 2, value: 'loan_information' },
      ],
      isBusy: false,
      paymentScheduleList: [],
      status: '',
      loanApplication: null,
      loanInformation: null,
    }
  },
  computed: {
    currentPeroid() {
      var period = this.paymentScheduleList.length
      for (var i = 0; i < this.paymentScheduleList.length; i++) {
        if (this.paymentScheduleList[i].status === 'unpaid') {
          period = i
          break
        }
      }
      return period
    },
    currentPaymentDate() {
      var pmt_date = null
      for (var i = 0; i < this.paymentScheduleList.length; i++) {
        if (this.paymentScheduleList[i].status === 'unpaid') {
          pmt_date = this.paymentScheduleList[i].pmt_date
          break
        }
      }
      return pmt_date
    },
    totalLateDate() {
      if (this.currentPaymentDate) {
        const paymentDate = moment(this.currentPaymentDate, 'YYYY-MM-DD')
        const current = moment()

        const diff = current.diff(paymentDate, 'days')
        if (diff > 0) {
          return diff
        }
        return null
      }
      return null
    },
    paymentReferenceNumber() {
      return this.loanInformation ? this.loanInformation.qrcode.string : ''
    },
    paymentReferencePhoto() {
      return this.loanInformation ? this.loanInformation.qrcode.photo : ''
    },
  },
  created() {
    this.getLoanApplication()
  },
  methods: {
    async getLoanApplication() {
      try {
        this.isBusy = true
        const res = await LoanService.loanApplication()
        this.isBusy = false
        if (res.status == 200) {
          const loanResponse = res.data.data
          this.loanApplication = loanResponse
          this.status = loanResponse.status
          if (this.status == 'disbursed') {
            this.getLoanInformation()
          } else {
            this.$router.push({ path: 'home' })
          }
        }
      } catch (err) {
        this.isBusy = false
        this.showAlert(err)
      }
    },
    async getLoanInformation() {
      try {
        this.isBusy = true
        const res = await LoanService.loanInformation()
        this.isBusy = false
        if (res.status == 200) {
          const loanResponse = res.data.data
          this.loanInformation = loanResponse
          this.paymentScheduleList = loanResponse.schedule
        }
      } catch (err) {
        this.isBusy = false
        this.showAlert(err)
      }
    },
    repaymentPeriod(value) {
      if (!value) return ''
      const period = parseInt(value)
      switch (period) {
        case 1:
          return this.$t('first_repayment')
        case 2:
          return this.$t('second_repayment')
        case 3:
          return this.$t('third_repayment')
        case 4:
          return this.$t('fourth_repayment')
        case 5:
          return this.$t('fifth_repayment')
        case 6:
          return this.$t('sixth_repayment')
        case 7:
          return this.$t('seven_repayment')
        case 8:
          return this.$t('eighth_repayment')
        default:
          return period
      }
    },
    getUrl(url) {
      if (this.$i18n.locale == 'th') {
        return `${url}/th`
      }
      return `${url}/mm`
    },
    copyToClipboard() {
      this.$bvModal.hide('modal_barcode')
      let testingCodeToCopy = document.querySelector('#testing-code')
      testingCodeToCopy.setAttribute('type', 'text') // 不是 hidden 才能複製
      testingCodeToCopy.select()

      try {
        var successful = document.execCommand('copy')
        var msg = successful ? 'successful' : 'unsuccessful'
        this.showAlert('ငွေပေးချေမှုရည်ညွှန်းနံပါတ်ကို ကူးယူပြီးပါပြီ')
      } catch (err) {
        // this.showAlert('Oops, unable to copy')
      }

      /* unselect the range */
      testingCodeToCopy.setAttribute('type', 'hidden')
      window.getSelection().removeAllRanges()
    },
  },
}
</script>

<style></style>
